.exam-btn{
    position: relative;
    bottom: 23px;
}
.wrapper{
    margin-top: 110px;
}
.addExam-container, .profile-container{
    .input-container, .css-2b097c-container, select, textarea{
        width: 86%;
    }
    .css-2b097c-container, select{
        margin: 8px 0;
    }
    .btn-group{
        display: flex;
        .column{
            margin: 4px 13px;
            margin-top: 12px;
            margin-bottom: 10px;
        }
    }
}
.addExam-container{
    .fileUploader {
        width: 27%;
        .fileContainer{
          padding-top: 0px;
          margin: 0;
          box-shadow: none;
        }
    }
}
.exam-table{
    margin: 4.5em 0 !important;
}
.age_inputs{
    display: block;
    margin-top: -14px;
    label{
        font-size: 14px;
        font-weight: 500;
    }
    label:first-child{
        margin-right: 6px;
    }
    .field{
        display: inline-block;
        input{
            width: 65%;
        }
        .errorMsg{
            position: absolute;
        }
    }
    label:nth-of-type(2){
        margin-right: 9px;
        margin-left: -4.5rem;
    }
}
.input-field{
    display: flex;
    .input-container{
        width: 175%
    }
    p{
        margin-top: 2rem;
        font-size: 11px;
        font-weight: 600;
        margin-left: 14rem;
    }
}

