.link-container{
    text-align: left;
    display: block;
    margin: 16px;
    left: -3%;
    position: relative;
}
.exam-content{
    top: -2.5rem;
}
.course-inf-link{
    color: #4183c4 !important;
}
.add-btn{
    position: relative;
    bottom: 50px;
}
.heading-container{
    position: relative;
    left: -15px;
}
.form-data {
    text-align: left;
    .view-btn, .edit-btn, .delete-btn{
        background: #fff;
        position: relative;
        top: -7px;
    }
    .view-btn{
        color: blue;
    }
    .edit-btn{
        color: green;
    }
    .delete-btn{
        color: red;
    }
    .status-select{
        padding-right: 0px !important;
        .css-2b097c-container .css-yk16xz-control{
            width: 110%;
        }
    }
    .status-update-btn{
        padding-left: 18px !important;
        padding-top: 14px !important;
    }
    .user-status{
        padding-right: 0px !important;
        .css-2b097c-container .css-yk16xz-control{
            width: 75%;
        }
    }
    .user-status-btn{
        padding-top: 14px !important;
    }
}
.ui.segment{
    padding: 20px 20px 20px 40px;
}
.btns-container{
    float: left;
    margin-top: 12px;
    margin-bottom: 10px;
    .ui.button:first-child{
        margin-right: 15px;
    }
}
.label-heading{
    bottom: 10px;
    position: relative;
}
.levels-container{
    display: -webkit-inline-box;
    label:first-of-type{
        position: relative;
        top: -22px;
        left: 3px;
    }
    label{
        left: -32px;
        top: -22px;
        position: relative;
    }
    .level-container:first-of-type{
        position: relative;
        top: 5px;
        left: -34px;
    }
    .level-container{
        position: relative;
        top: 5px;
        left: -78px;
    }
}
.salary-level-container{
    display: -webkit-inline-box;
    label:first-of-type{
        position: relative;
        top: -22px;
        left: 3px;
    }
    label{
        left: -32px;
        top: -22px;
        position: relative;
    }
    label:nth-of-type(3){
        left: -7rem;
        top: -22px;
        position: relative;
    }
    .level-container:first-of-type{
        position: relative;
        top: 5px;
        left: -34px;
    }
    .level-container:nth-of-type(3){
        position: relative;
        top: 5px;
        left: -11.5rem;
    }
    .level-container{
        position: relative;
        top: 5px;
        left: -68px;
    }
}
.add-levels{
    position: relative;
    left: -8rem;
    top: 4px;
}
.display-image{
    width: 12%;
    position: relative;
    top: 2px;
}
.add-subject{
    .ui.button{
        margin-left: 20px;
    }
}
.options-container{
    display: -webkit-inline-box;
    margin-top: 8px;
    margin-left: -0.2rem;
    label:first-of-type{
        position: relative;
        top: -22px;
        left: 3px;
    }
    label{
        left: -11rem;
        top: -22px;
        position: relative;
    }
    .option-container{
        .option_1{
            left: -10.5rem;
            position: relative; 
        }
    }
    .option-container:first-child{
        position: relative;
        top: 5px;
        left: 4px;
    }
    .checkbox-container{
        .single-checkbox-1{
            position: relative;
            left: -14.5rem;
            top: 14px;
        }
        .single-checkbox{
            position: relative;
            top: 23px;
            left: 29px;
        }
    }
}

.true_false_options-container{
    display: -webkit-inline-box;
    margin-top: 8px;
    margin-left: -0.2rem;
    label{
        top: -22px;
        position: relative;
    }
    .true_false_option-container{
        .option_1{
            left: -10.5rem;
            position: relative; 
            top: 13px;
        }
    }
    .true_false_option-container:first-child{
        position: relative;
        top: 40px;
        left: 5.5px;
    }
    .checkbox-container{
        .single-checkbox-1{
            position: relative;
            left: -4.5rem;
            top: 14px;
        }
        .single-checkbox{
            position: relative;
            top: 18px;
            left: 12.2rem;
        }
    }
}